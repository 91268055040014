import request from '@common/http';
import { ConfigProvider, message, Modal, Tooltip } from 'antd';
import zh_CN from 'antd/es/locale-provider/zh_CN';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import qs from 'qs';
import React from 'react';
import ReactDOM from 'react-dom';
import event from './eventHub';
const key = CryptoJS.enc.Utf8.parse('gb4t976dwe2kj239');
const iv = CryptoJS.enc.Utf8.parse('8269571069351229');
const env = process.env.NODE_ENVE;
const appCenterUrl =
    env === 'prod'
        ? 'https://apps.ecaisys.com'
        : env === 'pre'
        ? 'https://apps.pre.ecaisys.com'
        : 'https://apps.ecaiabc.com';

// 跳转至应用中心
export function openApplyCenter() {
    request.get(API.supplyToken).then((res) => {
        window.open(`${appCenterUrl}/preparePage?token=${res.data}&type=1`);
    });
}

// 文件下载
export { default as uploadFile } from '@common/uploadFile';
export {
    limitStr,
    limitNumber,
    genId,
    genActiveServiceItemId,
    getActiveCompanyServiceItemIds,
    evBus,
    polling,
    openPdf
};

// const imgTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp']
const imgTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/heic'];

/**
 * 根据文件后缀名（包含.）判断是否是图片（支持所有格式，该方法一般用于头像等非业务用途图片上传）
 * @param type
 * @returns {boolean}
 */
export function determineAllImgWithSuffix(type) {
    return type && /(gif|jpg|jpeg|png|heic|tiff|bmp|webp)$/.test(type.toLowerCase());
}

/**
 * 根据文件后缀名（包含.）判断是否是图片（仅支持常见的几种静态图片格式，该方法一般用于业务逻辑中）
 * @param type
 * @returns {boolean}
 */
export function determineStaticImgWithSuffix(type) {
    // return type && /(jpg|jpeg|png|webp)$/.test(type.toLowerCase())
    return type && /(jpg|jpeg|png|heic)$/.test(type.toLowerCase());
}

/**
 * 判断是否是静态图片或PDF
 * @param type
 * @returns {boolean}
 */
export function determineStaticImgOrPdf(type) {
    // return type && /(jpg|jpeg|png|webp|pdf)$/.test(type.toLowerCase())
    return type && /(jpg|jpeg|png|pdf|heic)$/.test(type.toLowerCase());
}

/** 根据 https 分割多个图片地址 */
export function splitImgUrl(url) {
    if (!url) {
        return [];
    }
    // 前缀
    const prefix = /,?https?:\/\//g;
    return url
        .split(prefix)
        .filter((item) => item)
        .map((item) => `https://${item}`);
}

/**
 * 判断文件大小是否不超过 size M
 * @param size
 */
export function inSizeM(size, max) {
    return size / 1024 / 1024 < max;
}

function limitStr(str, byteLimit = 255) {
    // 匹配汉字正则
    const chinRegexpRule = /[\u2E80-\u9FFF]/g;
    // rule  chin*4 eng*1.2
    let matchs = str.match(chinRegexpRule);
    const totalLetterCount = str.length;
    if (!matchs) {
        matchs = [];
    }

    return matchs.length * 4.3 + (totalLetterCount - matchs.length) * 1.2 > byteLimit;
}

/* 限制数字输入框只能输入整数 */
function limitNumber(value) {
    if (typeof value === 'string') {
        return !isNaN(Number(value)) ? value.replace(/^(0+)|[^\d]/g, '') : '';
    } else if (typeof value === 'number') {
        return !isNaN(value) ? String(value).replace(/^(0+)|[^\d]/g, '') : '';
    } else {
        return '';
    }
}

// 推广任务 重构 参数兼容
function genId({ companyServiceItemId, serviceItemId, id }) {
    return companyServiceItemId || id || serviceItemId;
}

function genActiveServiceItemId({ companyServiceItemId, serviceItemId, id }) {
    return companyServiceItemId || serviceItemId;
}

/**
 * 从数组中抽取出companyServiceItemId或serviceItemId列表
 * @param data
 * @returns {*[]}
 */
function getActiveCompanyServiceItemIds(data) {
    let newData = [];

    _.map(data, (i) => {
        const activeServiceItemId = i.companyServiceItemId ? i.companyServiceItemId : i.serviceItemId;
        newData = newData.concat(activeServiceItemId);
    });

    return newData;
}

class EventBus {
    constructor() {
        this.refPools = Object.create(null);
    }

    emit(onEvt, params) {
        const lisQue = this.refPools[onEvt];
        if (!lisQue) {
            return;
        }
        lisQue.forEach((cb) => {
            cb(params);
        });
    }

    on(onEvt, cb) {
        let lisQue = this.refPools[onEvt];
        lisQue = (lisQue || []).concat(cb);
        this.refPools[onEvt] = lisQue;
    }
}

const evBus = new EventBus();

/**
 * 获取url参数
 * @param  {[type]} name [description]
 * @return {[type]}      [description]
 */
export function getUrlParam(name) {
    const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
    const r =
        window.location.search.substr(1).match(reg) ||
        window.location.hash.substring(window.location.hash.search(/\?/) + 1).match(reg);
    if (r != null) {
        return decodeURIComponent(r[2]);
    }
    return null;
}

/**
 * AES加密
 * @param text
 * @returns {string}
 */
export function encryptWithAES(text) {
    const encryptedText = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), key, {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return CryptoJS.enc.Base64.stringify(encryptedText.ciphertext);
}

/**
 * 创建弹窗
 * @param {Modal}
 * @return {object} show
 */
export function Dialog(Com) {
    return (options = {}) => {
        let node;
        function close() {
            ReactDOM.unmountComponentAtNode(node);
        }
        return {
            show: () => {
                node = document.createElement('div');
                ReactDOM.render(
                    <ConfigProvider locale={zh_CN}>
                        <Com {...options} onClose={close} />
                    </ConfigProvider>,
                    node
                );
            }
        };
    };
}
// 轮询请求
function polling(fn, timeout) {
    let timer;
    const cb = async () => {
        if (fn) {
            const res = await fn();
            if (res === false) {
                return;
            }
        }
        if (timer) {
            timer = setTimeout(cb, timeout);
        }
    };
    timer = setTimeout(cb, 0);
}
// 劳动合同 获取法大大 已经签章签字的pdf
const openPdf = async (laborContractId, url) => {
    if (!laborContractId && !url) {
        return;
    }
    if (!laborContractId && url) {
        window.open(url);
        return;
    }
    try {
        message.loading('正在加载pdf文件,请稍后...');
        const res = await request({
            url: `/api/labor/contract/${laborContractId}/pdf`,
            responseType: 'blob'
        });
        const url = window.URL.createObjectURL(res.data);
        message.destroy();
        window.open(url);
    } catch (err) {
        message.destroy();
        message.error('加载pdf文件失败!');
    }
};

export function setDefaultCompany(companyId) {
    $.AJAX({
        url: `${API.userInCompany}/${companyId}`,
        type: 'PUT'
    });
}

export function logout() {
    window.localStorage.removeItem('AUTHORIZATION'); // authorization
    window.localStorage.removeItem('COMPANYID'); // authorization
    window.localStorage.removeItem('COMPANYNAME'); // authorization
    window.localStorage.removeItem('AUTHENTICATIONSTATUS'); // authorization
    window.localStorage.removeItem('USERNAME'); // authorization
    window.localStorage.removeItem('SHOWCREATE'); // authorization
    window.localStorage.removeItem('USERID'); // authorization
    window.localStorage.removeItem('SHOWENTER'); // authorization
    window.localStorage.removeItem('SUPER'); // authorization
    window.localStorage.removeItem('INDEX'); // authorization
    window.localStorage.removeItem('SELECTTYPE'); // authorization
    window.localStorage.removeItem('FREEZE'); // authorization
    window.localStorage.removeItem('TASKITEMID'); // authorization
    window.localStorage.removeItem('ADDRESS'); // authorization
    window.localStorage.removeItem('TIME'); // authorization
    window.localStorage.removeItem('ISDIRECTLY'); // authorization
    window.localStorage.removeItem('COMPANYTYPE'); // authorization
    window.localStorage.removeItem('IsRealName'); // authorization
    window.localStorage.removeItem('isSuper'); // authorization
    window.localStorage.removeItem('isPass'); // authorization
    window.localStorage.removeItem('MODULE_PERMIT');
    window.localStorage.removeItem('SYS_EDITION'); // 标准版/专业版

    // 放一个标识，登录页面需要刷新
    window.sessionStorage.setItem('reloadLogin', 'true');
    window.location.href = '/#/login';
}

/**
 * 获取不同环境的网址
 * @param {*} env 环境变量
 * @returns
 */
export function getEnvLink(env = process.env.NODE_ENVE) {
    switch (env) {
        case 'dev':
            return 'https://gjdev.ecaiabc.com/#/';
        case 'dev2':
            return 'https://gj.dev.ecaiabc.com/#/';
        case 'test':
            return 'https://gj.ecaiabc.com/#/';
        case 'prod':
            return 'https://gj.ecaisys.com/#/';
        case 'pre':
            return 'https://gj.pre.ecaisys.com/#/';
        default:
            return;
    }
}

/**
 * 截取url文件名
 * @param {string} url
 * @return {string}
 */

export function subUrlFileName(url) {
    if (typeof url !== 'string') {
        return url;
    }
    const res = url.split(/\//g);
    const len = res.length - 1;
    return decodeURI(res[len]);
}

/** 获取URL的文件名 */
export function getUrlFileName(url) {
    try {
        const parsedUrl = new URL(url);
        const { pathname } = parsedUrl;
        const segments = pathname.split('/').filter(Boolean);
        const filename = segments.pop();
        return filename;
    } catch (error) {
        console.error('Error parsing URL:', error);
        return '';
    }
}

// 动态修改面包屑
// demo
// setBreadcrumb('市场管理,远程拜访')
// or setBreadcrumb(['市场管理','远程拜访'])
// or setBreadcrumb([{name:'市场管理',url:'xxx'},{name:'远程拜访',url:'xxx'}])
export function setBreadcrumb(data) {
    // 延迟到下一帧执行，将修改面包屑操作放在layout 组件修改面包屑之后
    setTimeout(() => {
        event.emit('setBreadcrumb', data);
    });
}
/**
 * 判断时间范围是否超过限制
 * @param {*} startDate
 * @param {*} endDate
 * @param {*} limit
 * @returns
 */
export function judgePeriodExceed(startDate, endDate, limit = 100) {
    if (
        !startDate ||
        !endDate ||
        new Date(endDate).getTime() - new Date(startDate).getTime() > 1000 * 60 * 60 * 24 * limit
    ) {
        return true;
    } else {
        return false;
    }
}

/**
 * 校验变量是否为数字且值不为NaN
 * @param {*} num
 * @returns
 */
export function checkNumWhetherEmpty(num) {
    const isNumber = Object.prototype.toString.call(num) === '[object Number]';
    return isNumber ? (isNaN(num) ? false : true) : false;
}

/**
 * 获取上下游H5项目域名
 * @param {*} type 1-上游 2-下游
 */
export function getH5Address(type) {
    const upEnum = {
        dev: 'https://gjmdev.ecaiabc.com',
        dev2: 'https://gjmdev2.ecaiabc.com',
        test: 'https://gjm.ecaiabc.com',
        pre: 'https://gjmpre.ecaisys.com',
        prod: 'https://gjm.ecaisys.com'
    };
    const downEnum = {
        dev: 'https://ysmdev.ecaiabc.com',
        dev2: 'https://ysmdev2.ecaiabc.com',
        test: 'https://ysm.ecaiabc.com',
        pre: 'https://ysmpre.ecaisys.com',
        prod: 'https://ysm.ecaisys.com'
    };
    return type === 1 ? upEnum[process.env.NODE_ENVE] : downEnum[process.env.NODE_ENVE];
}
/**
 * @description: 推广产品列表 内容处理
 * @param {array} productVOList 推广产品数据
 * @param {number} len 取前几个 不填取全部
 * @return {string} 推广产品 str 后内容
 */
export function productsDetailName(productVOList, options = {}) {
    const { valueKey = 'detailName', joinValue = '；', len } = options;
    const resetProductVOList = Array.isArray(productVOList) ? productVOList : [];
    if (resetProductVOList.length === 0) {
        return null;
    }
    const data = resetProductVOList.map((item) => {
        let content = typeof valueKey === 'function' ? valueKey(item) : item[valueKey];
        // joinValue 采用 换行形式的，去掉 内容里面的换行符
        if (joinValue === '\n') {
            content = content.replace(/\n/g, '');
        }
        return content;
    });
    if (len) {
        const list = data.filter((item, index) => index + 1 <= len);
        return (
            <div>
                {list.map((item) => {
                    return (
                        <div key={item}>
                            <Tooltip title={item}>{item.length > 6 ? `${item.slice(0, 6)}...` : item}</Tooltip>
                        </div>
                    );
                })}
                {data.length > len ? <div>...（共{data.length}个）</div> : null}
            </div>
        );
    }
    return data.join(joinValue);
}

/**
 * 展示终端位置信息
 * @param {*} visitAddress
 * @param {*} needOmitting 是否需要省略
 * @returns
 */
export function showVisitAddress(visitAddress, needOmitting = false) {
    if (!visitAddress?.address || !visitAddress?.name) {
        return '-';
    }
    const { address, name } = visitAddress;
    const str = address + name;
    return str.length > 5 && needOmitting ? <Tooltip title={str}>{`${str.slice(0, 5)}...`}</Tooltip> : str;
}

/**
 * 隐藏签到时间的秒
 * @param {*} time
 * @returns
 */
export function hideSecondeOfSignTime(time = '') {
    if (!time) return '-';
    return time.substring(0, time.length - 3);
}

/**
 * 格式化公司名称，用于处理个人版
 * @param {*} name
 * @returns
 */
export function formatCompanyName(name = '') {
    if (!name?.length && typeof name !== 'string') return '-';
    return name.replace(/&grb[0-9]*$/, '');
}

/**
 * 处理表格列的最小宽度
 * @param {*} children
 * @param {*} titleLength 表头字数
 * @param {*} customWidth 自定义宽度
 * @returns
 */
export function handelColumnWidth(children, titleLength, customWidth = 0) {
    return <div style={{ minWidth: customWidth || titleLength * 15 }}>{children}</div>;
}

/** 获取系统版本 0-标准版 1-专业版 */
export function getSysEditing() {
    return window.localStorage.getItem('SYS_EDITION');
}

/** 是否是标准版 */
export function isStandard() {
    return getSysEditing() === '0';
}

/** 是否是专业版 */
export function isProVersion() {
    return getSysEditing() === '1';
}

/** 结算金额最大值 */
export const maxSettlement = 999999999.99;

/**
 * hera-ui Table 组件，在设置了saveId的情况下，获取了搜索条件字符串
 * @param {*} saveId
 * @param {*} params
 */
export const getHTableSaveIdParamsStr = (saveId, params) => {
    try {
        let data = qs.stringify(params);
        data = window.btoa(data);
        return `${saveId}=${data}`;
        // const newData = {};
        // newData[saveId] = data;
        // setUrlParams(newData);
    } catch (err) {
        // 加密失败
        throw new Error('Set HTable saveId Params failed');
    }
};

/** 是否有权限跳转到推广任务详情 */
export function isRoleOpenTaskDetail(options) {
    const { serviceItemId, refType, taskItemId } = options;
    if (refType === 99) {
        return true;
    }
    // 需要 taskItemId，taskItemId 不存在就不让跳转
    if ([43, 42, 14, 18, 12, 13, 41, 19, 42, 5, 11, 15, 16, 17].includes(serviceItemId) && !taskItemId) {
        return false;
    }
    // 3个拜访 学术活动类的 不让跳转
    if ([1, 2, 3, 6, 7, 8, 9, 10].includes(serviceItemId)) {
        return false;
    }
    return true;
}

/** 打开推广任务详情 */
export function openTaskDetail(options) {
    const { serviceItemId, dataId, refType, taskId, taskItemId, fields } = options;
    if (refType === 99) {
        const name = fields.find((item) => item.name === '照片来源')?.value;
        if (name) {
            localStorage.setItem('ADJUNCTNAME', name);
        }
        window.open(
            `/#/BusinessData/BusinessDataDetailUploadFIleArea/${dataId}?taskId=${taskId}&serviceItemId=${serviceItemId}`
        );
    } else if (serviceItemId === 1) {
        // 临床拜访
    } else if (serviceItemId === 2) {
        // 药店拜访
    } else if (serviceItemId === 4) {
        // 终端维护
        window.open(
            `/#/BusinessData/BusinessDataDetail/TerminalMaintenanceDetail?id=${dataId}&taskId=${taskId}&maintenanceDateDesc=true`
        );
    } else if ([6, 7, 8, 9, 10].includes(serviceItemId)) {
        // 学术活动类
    }
    // else if ([15, 16, 17].includes(serviceItemId)) {
    //     // 终端调研、商业配送机构调研、竞品信息反馈、收集商业流向数据
    //     window.open(
    //         `/#/BusinessData/BusinessDataDetail/FlowDataDetailTab1?id=${dataId}&taskId=${taskId}&taskItemId=${taskItemId}`
    //     );
    // }
    else if ([23, 24, 25, 26].includes(serviceItemId)) {
        // 药店准入、医院准入
        // window.open(`/#/BusinessData/BusinessDataDetail/AdmittancePlanDetail?id=${dataId}&taskId=${taskId}&attachmentId=965631282530746368&closePage=true`);
    } else if (serviceItemId === 3) {
        // 渠道拜访
    } else if ([15, 16, 17, 43, 42, 14, 18, 12, 13, 41, 19, 42, 5, 11].includes(serviceItemId)) {
        // 学术拜访、患者随访、收集终端用药信息 区域药品招标信息收集、收集其他终端库存信息、收集药店库存信息、区域临床路径信息收集、商业渠道维护、收集商业流向数据、收集医院库存信息
        window.open(
            `/#/BusinessData/BusinessDataDetail/usePharmacyDetail?id=${dataId}&taskId=${taskId}&taskItemId=${taskItemId}&serviceItemId=${serviceItemId}`
        );
    }
}

/**
 * 格式化时间
 * @param {*} date
 * @param { number | string } format
 * @returns {string}
 */
export const formatTimeString = (date, format = 1) => {
    if (!date) return null;
    const formatEnum = {
        1: 'YYYY-MM-DD',
        2: 'YYYY-MM-DD HH:mm:ss',
        3: 'YYYY年MM月DD日',
        4: 'YYYY年MM月DD日之前',
        5: 'YYYY年MM月DD日之后'
    };
    const _format = typeof format === 'number' ? formatEnum[format] || formatEnum[1] : format;

    return moment(date).format(_format);
};

/**
 * 使用位运算解析value返回开启的项目
 * @param {*} value
 * @returns
 */
export function getEnabledBits(value) {
    if (!value || 'number' !== typeof +value) return [];
    const res = Array.from(value.toString(2))
        .reverse()
        .map((val, index) => {
            return val === '1' ? 2 ** index : 0;
        })
        .filter(Boolean);
    return res;
}
